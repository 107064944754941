import React, { useContext } from 'react';
import { BotContext } from '../../common/context/bot.context';
import './trigger.scss';
import { PHASE } from '../../common/constants/story.constants';

function SuggestionsTrigger() {
  const { toggleSuggestions, updatePhase, pauseComp } = useContext(BotContext);

  const onTriggerClick = () => {
    toggleSuggestions(true);
    updatePhase(PHASE.paused);
    pauseComp();
  }

  return (<div className="suggestions__trigger" onClick={onTriggerClick} />);
}

export default SuggestionsTrigger;
