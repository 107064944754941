import React from 'react';
import './log.scss';

function Log({ records, onClear }) {
  return (
    <div className="log">
      {
        records.length ? (
          <div className="log__content">
            <button className="log__clear" onClick={onClear}>Clear</button>

            {
              records.map((record, i) => (
                <div className="log__item" key={`logitem--${i}`}>
                  { record }
                </div>
              ))
            }
          </div>) : null
      }
    </div>
  );
}

export default Log;