import React from 'react';
import ReactDOM from 'react-dom';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BotContextProvider } from './common/context/bot.context';

const bugsnagClient = bugsnag({
  apiKey: '89438b907fa4d5020cfc4fd31246f74e',
});
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
  <ErrorBoundary>
    <BotContextProvider bs={bugsnagClient}>
      <App />
    </BotContextProvider>
  </ErrorBoundary>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
