import React from 'react';
import './loading.scss';

function Loading() {
  return (
    <div className="page loading">
      <div className="loading__content">
        <div className="loading__circle"></div>
        <div className="loading__circle"></div>
      </div>
    </div>
  );
}

export default Loading;