import React, { useContext } from 'react';
import { BotContext } from '../../common/context/bot.context';
import './battery.scss';
import { ReactComponent as Icon } from './charging.svg';

function Battery() {
  const {
    charging,
    batteryLevel
  } = useContext(BotContext);

  return (
    <div className={`batterylevel__wrapper${charging ? ' is-charging' : ''}`}>
      <div className="batterylevel">
        <div className={`batterylevel__level`} style={{ width: `${batteryLevel}%`, color: `${batteryLevel > 30 ? 'green' : 'orange'}` }}></div>
        <div className="batterylevel__icon">{ charging ? (<Icon />) : null }</div>
      </div>
    </div>
  );
}

export default Battery;