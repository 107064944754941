import React, { useContext } from 'react';
import { BotContext } from '../../common/context/bot.context';
import { PHASE } from '../../common/constants/story.constants';
import './suggestions.scss';

function Suggestions() {
  const { toggleSuggestions, updatePhase, suggestions, resumeComp } = useContext(BotContext);

  const onCloseClick = () => {
    toggleSuggestions(false);
    updatePhase(PHASE.start);
    resumeComp();
  };

  return (
    <div className="page suggestions__wrapper">
      <div className="suggestions__close" onClick={onCloseClick} />

      <div className="suggestions__scrollfix__wrapper">
        <div className="suggestions__scrollfix">
          <div className="suggestions__content">
              <div className="suggestions">

                { suggestions.map(({ visual, title, highlight, price, description, info, note }, i) => (
                  <div className="suggestion" key={`suggestion--${i}`}>
                    <div className="suggestion__visual__wrapper">
                      <img src={ visual } className="suggestion__visual" alt={ description } />
                      { highlight ? (<img src={ highlight } className="suggestion__highlight" alt="menu highlight" />) : null }
                    </div>
                    
                    <div className="suggestion__inner"> 
                      <div className="suggestion__content">
                        <p className="suggestion__price">{ price }</p>
                        <h3 className="suggestion__title">{ title }</h3>
                        <p className="suggestion__description">{ description }</p>
                      </div>

                      { info.length ? (
                      <div className="suggestion__info__list">
                        <ul className="info__list">
                          { info.split(', ').map((label, j) => (
                          <li className="info__list__item" key={`suggestion--${i}--label--${j}`}>
                            <span className="info__list__symbol">
                              <svg viewBox="0 0 62 43" version="1" xmlns="http://www.w3.org/2000/svg"><g fill="#005E53" fillRule="evenodd"><path d="M40.164 21.147c4.756-4.973 8.479-7.293 8.479-7.293a85.538 85.538 0 0 0-6.04 7.293C35.18 31.181 32.148 39.865 31.398 42.28c-.135.434-.197.667-.197.667a30.902 30.902 0 0 0 1.61-.259c12.766-2.359 20.042-12.077 24.156-21.54 4.443-10.22 5.2-20.14 5.2-20.147h-.002C44.01 3.838 35.51 12.764 31.56 21.147c-4.128 8.764-3.282 16.936-3.282 16.936 3.724-7.417 8.127-13.005 11.886-16.936M24.182 37.464s-3.325-7.987-8.936-16.072c-2.735-3.942-6.011-7.906-9.715-10.98 0 0 5.892 1.909 13.388 10.98 2.538 3.072 5.26 6.962 8 11.882-.227-2.643-.163-6.676 1.095-11.882.01-.048.02-.095.033-.143C25.31 15.066 17.957 8.217.016 4.199c0 0-.42 8.68 3.035 17.193 2.269 5.587 6.21 11.1 13.035 14.046 2.116.913 4.508 1.578 7.214 1.922.292.038.583.075.882.104"></path></g></svg>
                            </span>
                            <span className="info__list__label">{ label }</span>
                          </li>
                          )) }
                        </ul>
                      </div>
                      ) : null }

                      { note ? (
                      <div className="suggestion__footer">
                        <sup>*</sup> { note }
                      </div>
                      ) : null }
                    </div>
                  </div>
                )) }

              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Suggestions;
