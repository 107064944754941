import React, { useState, createContext, useEffect } from 'react';
import * as automl from '@tensorflow/tfjs-automl';
import mqtt from 'mqtt';
import moment, { duration } from 'moment';
import { PHASE } from '../constants/story.constants';
import { getDevices, getMediaStream } from '../services/webcam.service';
import config from '../config';
import { getSuggestions } from '../services/backend.service';
import { STORE } from '../constants/storage.constants';
import { MQTT } from '../constants/mqtt.constants';
import { preloadVideo } from '../services/video.service';

export const BotContext = createContext({
  formShown: false,
  toggleForm: () => {},

  log: [],
  addToLog: () => {},
  clearLog: () => {},

  loading: true,
  showLoader: () => {},
  hideLoader: () => {},

  botid: '',
  updateBotId: () => {},

  botname: '',
  updateBotName: () => {},

  botlocation: '',
  updateBotLocation: () => {},

  phase: '',
  updatePhase: () => {},

  model: {},

  lang: '',
  updateLang: () => {},
  changeLanguage: () => {},

  client: null,
  connected: false,

  stream: null,

  fullscreen: false,
  goFullscreen: () => {},
  exitFullscreen: () => {},

  videLoaded: false,
  updateVideoLoaded: () => {},

  showSuggestions: false,
  toggleSuggestions: () => {},
  loadSuggestions: (lang) => {},

  suggestions: [],
  updateSuggestions: () => {},

  charging: false,
  docked: false,
  batteryLevel: 50,

  resumeComp: () => {},
  pauseComp: () => {},

  volume: 0,
  setVolume: () => {},

  disableBack: () => {},
});

export const BotContextProvider = ({ bs, children }) => {
  const [ loading, setLoader ] = useState(true);

  const _botid = localStorage.getItem(STORE.BOTID);
  const _botname = localStorage.getItem(STORE.BOTNAME);
  const _botlang = localStorage.getItem(STORE.BOTLANG);
  const _botlocation = localStorage.getItem(STORE.BOTLOCATION);

  const [ botid, updateBotId ] = useState(_botid || '');
  const [ botname, updateBotName ] = useState(_botname || '');
  const [ botlocation, updateBotLocation ] = useState(_botlocation || '');
  const [ lang, updateLang ] = useState(_botlang || 'nl');
  const [ client, updateClient ] = useState(null);
  const [ model, updateModel ] = useState({});
  const [ phase, updatePhase ] = useState(PHASE.start);
  const [ formShown, toggleForm ] = useState(!_botid || !_botname || !_botlang || !_botlocation);
  const [ log, updateLog ] = useState([]);
  const [ stream, updateStream ] = useState(null);
  const [ videLoaded, updateVideoLoaded ] = useState(false);
  const [ showSuggestions, toggleSuggestions ] = useState(false);
  const [ suggestions, updateSuggestions ] = useState([]);
  const [ buttonPressed, updateButtonPressed ] = useState(false);

  const [ composition, updateComposition ] = useState({});

  const [ charging, updateCharging ] = useState(false);
  const [ docked, updateDocked ] = useState(false);
  const [ dockingDate, updateDockingDate ] = useState(null);
  const [ updatedTime, updateUpdatedTime ] = useState(moment());
  const [ batteryLevel, updateBatteryLevel ] = useState(50);
  const [ connected, updateConnected ] = useState(false);

  const [ volume, updateVolume ] = useState(66);

  const [ fullscreen, updateFullscreen ] = useState(!!window.document.fullscreenElement);

  let compTimer = null;
  let pauseCompTimer = null;

  const showLoader = () => {
    setLoader(true);
  };
  const hideLoader = () => {
    setLoader(false);
  };
  const addToLog = (entry) => {
    updateLog((oldlog) => [ entry, ...oldlog ]);
  };
  const clearLog = () => {
    updateLog([]);
  };
  const changeBotId = (value) => {
    localStorage.setItem(STORE.BOTID, value);
    updateBotId(value);
  };
  const changeBotName = (value) => {
    localStorage.setItem(STORE.BOTNAME, value);
    updateBotName(value);
  };
  const changeBotLocation = (value) => {
    localStorage.setItem(STORE.BOTLOCATION, value);
    updateBotLocation(value);
  };
  const loadModel = async () => {
    console.log('loading model');
    const _model = await automl.loadImageClassification('./model2/model.json');
    updateModel(_model);
    console.log('model loaded');
  };
  const connectClient = async () => {
    const options = {
      servers: [
        { host: config.mqttUrl, port: 9001 },
      ],
    };

    if (config.debug) {
      options.servers = [
        { host: config.mqttUrl, port: 9001 },
        // { host: '10.242.24.50', port: 9001 }, // elly
        // { host: '10.242.24.85', port: 9001 }, // bob
      ];
    }

    const _client = mqtt.connect(options);
    updateClient(_client);
  };
  const logError = (e) => {
    if (config.debug) { 
      addToLog(`General error: ${e}`); 
    } else {
      window.location.reload();
    }
  };
  const goFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      addToLog('Go fullscreen');
    }
  }
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen(); 
      addToLog('Exit fullscreen');
    }
  };
  const enableCamera = async () => {
    const cams = await getDevices();

    if (cams && cams.length) {
      const [{ deviceId }] = cams;
      const mediaStream = await getMediaStream(deviceId);

      updateStream(mediaStream);
    }
  };
  const loadSuggestions = async () => {
    const _suggestions = await getSuggestions(lang);
    
    if (_suggestions && _suggestions.length) {
      const _botid = botid === 'debug' ? 'robot1' : botid;
      const _filtered = _suggestions.filter(({ robots }) => robots.split(', ').includes(_botid));
      updateSuggestions(_filtered);
    }
  };
  const changeLanguage = (newLang) => {
    localStorage.setItem(STORE.BOTLANG, newLang);
    updateLang(newLang);
  };
  const startComp = (timeout = config.compStartTimeout) => {
    stopComp();

    if (charging && batteryLevel < config.chargedBatteryLevel) { return; }
    if (!composition) { return; }

    if (phase === PHASE.start || phase === PHASE.waiting) {
      if (config.debug) { console.log('START COMP', composition, `${MQTT.start}`, JSON.stringify(composition)); }

      addToLog('Start composition');

      compTimer = setTimeout(() => {
        try {
          client.publish(`${MQTT.start}/id`, `{"id":"${composition.id}","name":"${composition.id}"}`);
          addToLog('Started composition');
        } catch(e) {
          bs.notify(e);
        }
      }, timeout);
    }
  };
  const stopComp = () => {
    clearTimeout(compTimer);
    clearTimeout(pauseCompTimer);

    try {
      client.publish(`${MQTT.stop}`, '{}');
      addToLog('Composition stopped');
    } catch(e) {
      bs.notify(e);
    }
  };
  const pauseComp = () => {
    clearTimeout(pauseCompTimer);

    try {
      client.publish(MQTT.pause, '{}');
    } catch(e) { 
      bs.notify(e); 
    }
  };
  const resumeComp = (timeout = config.compStartTimeout) => {
    clearTimeout(pauseCompTimer);

    if (charging) { stopComp(); return; }

    try {
      if  (!charging) {
        pauseCompTimer = setTimeout(() => {
          client.publish(MQTT.resume, '{}');
        }, timeout);
      }
    } catch(e) {
      bs.notify(e);
    }
  };
  const getToTheCharger = () => {
    stopComp();
    client.publish(MQTT.gotoCharger, '{}');
  }
  const loadVideos = async () => {
    if (!videLoaded) {
      updateVideoLoaded(false);

      const { default: srcNL } = await import(`../../media/${botname}/video_nl.mp4`);
      const { default: srcFR } = await import(`../../media/${botname}/video_fr.mp4`);
      
      await Promise.all([preloadVideo(srcNL), preloadVideo(srcFR)]);
      
      updateVideoLoaded(true);
    }
  }
  const onFullscreenChange = () => {
    updateFullscreen(!!window.document.fullscreenElement);
  };
  const setVolume = (val) => {
    updateVolume(val);
    client.publish(`${MQTT.volume}/set`, val);
  }
  const disableBack = () => {
    let i = 10;
    while (i) {
      window.history.pushState({ mode: 'stayhere' }, 'Lunchbuddies', '/');
      i--;
    }
  };

  useEffect(() => {
    if (config.debug) { console.log('loading', client, stream, model, videLoaded); }
    if (stream && model && videLoaded) {
      hideLoader();
    } else {
      showLoader();
    }
  }, [ client, stream, model, videLoaded ]);

  useEffect(() => {
    if (botname && lang) {
      updateVideoLoaded(false);
      updateSuggestions([]);
      loadSuggestions(lang);
      loadVideos();

      bs.metaData = {
        ...bs.metaData,
        botname,
        lang,
      };
    }
  }, [ botname, lang ]);

  // on client connection
  useEffect(() => {
    if (client) {

      client.on(MQTT.connect, (msg) => {   
        addToLog(`MQTT connected`, client); 
        
        updateConnected(true);

        client.subscribe(`${MQTT.button}/event`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: topic sub button press', topic); }
        });
  
        client.subscribe(`${MQTT.pause}/event`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: topic sub pause event', topic); }
        });
  
        client.subscribe(`${MQTT.resume}/event`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: topic sub resume event', topic); }
        });

        client.subscribe(`${MQTT.start}/event`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: topic start composition event', topic); }
        });

        client.subscribe(`${MQTT.stop}/event`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: topic stop composition event', topic); }
        });
        
        client.subscribe(`${MQTT.list}/event`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: topic list composition event', topic); }
        });
        
        client.subscribe(`${MQTT.emptyBattery}/event`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: topic charging required event', topic); }
        });

        client.subscribe(`${MQTT.batteryChanged}/event`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: topic battery changed event', topic); }
        });

        client.subscribe(`${MQTT.batteryCharged}/event`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: topic charging required event', topic); }
        });

        client.subscribe(`${MQTT.led}/event`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: topic led event', topic); }
        });
        
        client.subscribe(`${MQTT.cerror}/event`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: topic composition error event', topic); }
        });

        client.subscribe(`${MQTT.volume}/response/bot`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: volume event', topic); } 
        });
        client.subscribe(`${MQTT.volume}/set`, (err, topic) => {
          if (config.debug) { console.log('subscribed to: volume set event', topic); } 
        });

        client.publish(MQTT.list);
        client.publish(`${MQTT.volume}/get`, JSON.stringify({ key: 'bot' }));
      });
  
      client.on(MQTT.message, (topic, message) => {
        if (config.debug) { console.log('all messages', topic, message); }
  
        if (topic === `${MQTT.button}/event`) {
          if (config.debug) { console.log('Button pressed', JSON.parse(message.toString())); }
  
          client.publish(MQTT.pause, '{}');
          updateButtonPressed(true);
          clearTimeout(pauseCompTimer);
        }

        if (topic === `${MQTT.emptyBattery}/event`) {
          console.log('needs charging', message);

          getToTheCharger();
        }

        if (topic === `${MQTT.batteryChanged}/event`) {
          const msg = JSON.parse(message.toString());
          if (config.debug) { console.log('Battery level changed', msg); }

          if (!msg.docked) {
            updateDockingDate(null);
          }

          updateBatteryLevel(msg.level);
          updateCharging(msg.charging);
          updateDocked(oldDocked => {
            if (!oldDocked && msg.docked) {
              stopComp();
            }
            if (!msg.docked) {
              updateDockingDate(null);
            }
            return msg.docked;
          });
          updateUpdatedTime(moment());

          if (msg.docked) {
            client.publish(MQTT.reset);
          } else {
            if (msg.level < 30) {
              getToTheCharger();
            }
          }
        }

        if (topic === `${MQTT.start}/event`) {
          if (message) {
            if (config.debug) { console.log('Start comp', message.toString()); }
          }
        }

        if (topic === `${MQTT.list}/event`) {
          console.log('COMP LIST', JSON.parse(message.toString()));
          
          const response = JSON.parse(message.toString());
          const comp = response.filter(({ name }) => name === `${config.compositionName}-${botlocation}`)[0];

          if (comp && comp.id) {
            console.log('COMP FOUND', comp.name, comp.id);

            if (!composition.id || composition.id !== comp.id) {
              updateComposition(comp);
            }
          }
        }

        if (topic === `${MQTT.led}/event`) {
          console.log('Bot LED event', JSON.parse(message.toString()));
        }

        if (topic === `${MQTT.volume}/response/bot`) {
          const msg = JSON.parse(message.toString());
          console.log('Bot volume event', msg);
          updateVolume(msg);
        }

        if (topic === `${MQTT.volume}/set`) {
          const msg = JSON.parse(message.toString());
          console.log('Bot volume set event', msg);
          updateVolume(msg);
        }
      });

      client.on(MQTT.error, () => {
        updateClient(null);
      });

    }

    return () => {
      if (client) {
        client.end();
      }
    }
  }, [ client ]);

  // starting & stopping compositions
  useEffect(() => {
    if (config.debug) {
      console.group('Docking updates');
      console.log('docking updates connected', connected);
      console.log('docking updates docked', docked);
      console.log('docking updates phase', phase, (phase === PHASE.start || phase === PHASE.waiting));
      console.log('docking updates docking date', dockingDate);
      console.log('docking updates composition', composition);
      console.log('docking updates battery level', batteryLevel);
      console.groupEnd();
    }

    if (!connected || !docked) { 
      return; 
    }
    if (!(phase === PHASE.start || phase === PHASE.waiting)) { return; }

    if (docked && !dockingDate) {
      updateDockingDate(moment());
    }

    const dockingDiff = updatedTime.diff(dockingDate, 'minutes');
    console.log('docked diff', dockingDiff);

    if (composition && batteryLevel >= config.chargedBatteryLevel) {
      if (dockingDiff) {
        if (dockingDiff >= config.minimumDockedWaitTime) {
          startComp(500);
        } else {
          stopComp();
          addToLog(`Waiting ${config.minimumDockedWaitTime - dockingDiff}m. until composition start`);
        }
      }
    }
  }, [ connected, composition, docked, batteryLevel, phase, dockingDate, updatedTime ]);

  useEffect(() => {
    addToLog(`PHASE: ${phase}`);

    if (phase && phase === PHASE.start) {
      loadSuggestions(lang);
    }
  }, [ phase ]);

  useEffect(() => {
    if (client && connected) {
      if (buttonPressed) {
        pauseComp();

        if (phase === PHASE.start || phase === PHASE.waiting) {
          updatePhase(PHASE.zoomin);
        }

        updateButtonPressed(false);
      }
    }
  }, [ client, connected, buttonPressed, phase ]);

  useEffect(() => {
    loadModel();
    connectClient();
    enableCamera();
    
    window.addEventListener('error', logError);
    window.document.addEventListener('fullscreenchange', onFullscreenChange);
    window.addEventListener('popstate', disableBack);

    return () => {
      window.removeEventListener('error', logError);
      window.document.removeEventListener('fullscreenchange', onFullscreenChange);
      window.removeEventListener('popstate', disableBack);
    };
  }, []);

  const props = {
    loading,
    showLoader,
    hideLoader,

    formShown,
    toggleForm,

    log,
    addToLog,
    clearLog,

    botid,
    updateBotId: changeBotId,

    botname,
    updateBotName: changeBotName,

    botlocation,
    updateBotLocation: changeBotLocation,

    phase,
    updatePhase,

    model, 

    lang,
    updateLang,
    changeLanguage,

    client,
    connected,

    fullscreen,
    goFullscreen,
    exitFullscreen,

    videLoaded,
    updateVideoLoaded,

    stream,

    loadSuggestions,
    showSuggestions,
    toggleSuggestions,

    suggestions,
    updateSuggestions,

    charging,
    docked,
    batteryLevel,

    resumeComp,
    pauseComp,

    bs,

    volume,
    setVolume,

    disableBack,
  };

  return (
    <BotContext.Provider value={ props }>
      { children }
    </BotContext.Provider>
  );
}