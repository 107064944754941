import axios from 'axios';

export const preloadVideo = async (url) => {
  const response = await axios.get(url, {
    responseType: 'blob',
    // timeout: 30000,
  });
  const vid = URL.createObjectURL(response.data);

  return vid;
}