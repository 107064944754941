// @flow

import { VIDEO_INPUT } from '../constants/camera.constants';
import config from '../config';

export async function getDevices() {
  try {
    if (window.location.protocol !== 'https:') {
      throw new Error('Not HTTPS');
    }
    if (navigator.mediaDevices) {
      const devices = await navigator.mediaDevices.enumerateDevices();

      if (!devices || !devices.length) {
        throw new Error('No devices found');
      }

      const cameras = devices.filter((device) => device.kind === VIDEO_INPUT);

      if (!cameras || !cameras.length) {
        throw new Error('No cameras found');
      }

      return await cameras;
    }

    throw new Error('Mediadevices API not supported');
  } catch (e) {
    return e;
  }
}

export const stopStream = (mediaStream) => {
  try {
    // mediaStream.getVideoTracks().forEach((track) => {
    mediaStream.getTracks().forEach((track) => {
      track.stop();
    });

    return null;
  } catch (e) {
    return e;
  }
};

export async function getMediaStream(deviceId) {
  try {
    if (navigator.mediaDevices) {
      const constraints = {
        ...config.constraints,
        video: {
          ...config.constraints.video,
          deviceId,
        },
      };

      config.debug && console.log('got mediaDevices', navigator.mediaDevices, constraints);

      return await navigator.mediaDevices.getUserMedia(constraints);
    }

    throw new Error('Mediadevices API not supported');
  } catch (e) {
    return e;
  }
}

export async function videoToImage($preview) {
  try {
    const $canvas = document.createElement('canvas');
    const ctx = $canvas.getContext('2d');
    const ar = $preview.videoWidth / $preview.videoHeight;

    const { width } = config.constraints.video;
    const height = width / ar;

    $canvas.width = width;
    $canvas.height = height;

    ctx.drawImage($preview, 0, 0, width, height);

    const data = await $canvas.toDataURL('image/jpeg');

    return data;
  } catch(e) {
    return e;
  }
}