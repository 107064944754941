import React, { useContext, useRef, useState, useEffect } from 'react';
import { BotContext } from '../../common/context/bot.context';
import config from '../../common/config';
import { PHASE, SCENE } from '../../common/constants/story.constants';
import './videoplayer.scss';
import { STORE } from '../../common/constants/storage.constants';

function VideoPlayer(props) {
  const {
    markers,
    ...videoProps
  } = props;

  const videoRef = useRef();

  const { 
    addToLog, 
    loading, 
    phase, 
    updatePhase, 
    updateVideoLoaded, 
    updateLang,
    resumeComp,
    fullscreen,
  } = useContext(BotContext);
  
  const [ currentScene, updateCurrentScene ] = useState({});
  const [ waitCount, updateWaitCount ] = useState(0);

  const videoCanPlayTrough = (e) => {    
    updateVideoLoaded(true);

    if (loading) {
      addToLog('Video can play without buffering');
      e.currentTarget.muted = false;
      playScene(SCENE.start);
    }
  }

  const playWaitingScene = () => { 
    const { label } = currentScene;
    const rnd = Math.random();

    updateWaitCount(waitCount + 1);
    updatePhase(PHASE.waiting);

    if (label !== SCENE.waiting1 && (waitCount > config.waitCountMax || waitCount === 0)) {
      playScene(SCENE.waiting1);
      updateWaitCount(0);
      return;
    } else if (label !== SCENE.waiting2 && rnd > .7) {
      playScene(SCENE.waiting2);    
      return;
    } else if (rnd > .3) {
      playScene(SCENE.start);
      return;
    }

    playScene(SCENE.waiting3);    
  };

  const restart = () => {
    if (config.debug) { console.log('restart'); }

    resumeComp();
    updateLang(localStorage.getItem(STORE.BOTLANG));
    updatePhase(PHASE.start);
    playScene(SCENE.start);
  }
  
  const onPlaying = () => {
    const { current: video } = videoRef;
    const { label, end } = currentScene;

    if (video.currentTime >= end) {
      if (phase === PHASE.start || phase === PHASE.waiting) {
        playWaitingScene();
        return;
      }

      if (phase === PHASE.zoomin) {
        if (label === SCENE.zoomin) {
          updatePhase(PHASE.scanning);
          video.muted = true;
        } else {
          playScene(SCENE.zoomin);
        }
        return;
      }

      if (phase === PHASE.scanning) {
        updatePhase(PHASE.scanning);
        playScene(SCENE.scanning);
        return;
      }

      if (label === SCENE.empty) {
        updatePhase(PHASE.end);
        return;
      }

      if (label === SCENE.end) {
        restart();
        return;
      }

      if (label === SCENE.end || label === SCENE.full || label === SCENE.noplate) {
        restart();
        return;
      }

      if (phase === PHASE.empty) {
        playScene(SCENE.empty);
        return;
      }

      if (phase === PHASE.dirty) {
        playScene(SCENE.full);
        return;
      }

      if (phase === PHASE.noplate) {
        playScene(SCENE.noplate);
        return;
      }

      // restart();
    }
  };

  const playScene = (sceneLabel) => {
    const { current: video } = videoRef;
    const scene = markers.filter(({ label }) => sceneLabel === label)[0];

    if (scene.label !== currentScene.label) {
      updateCurrentScene(scene);
      addToLog(`playing ${scene.label}`);
    }

    video.currentTime = scene.start;
  };

  useEffect(() => {
    if (loading) { return; }
    
    const { current: video } = videoRef;

    if (!video) { return; }
    
    if (phase === PHASE.scanning || phase === PHASE.paused) {
      video.pause();
      video.muted = true;
    } else if (phase && !video.playing) {
      video.play();
      video.muted = false;
    }

    const { label } = currentScene;

    if (phase === PHASE.end) {
      if (label === SCENE.empty) {
        playScene(SCENE.end);
        return;
      }
    }

    if (phase === PHASE.start) {
      if (label === SCENE.empty || label === SCENE.full || label === SCENE.noplate) {
        restart();
        return;
      }
    }
  }, [ phase, loading ]);

  useEffect(() => {
    const { current: video } = videoRef;

    video.muted = fullscreen;

    if (fullscreen) {
      video.play();
    } else {
      video.pause();
    }
  }, [fullscreen]);

  useEffect(() => {
    if (!loading) {
      playScene(SCENE.start);
    }
  }, [ videoProps.src, loading ]);

  return (
    <div className="videoplayer page">
      <video 
        ref={videoRef} 
        playsInline 
        autoPlay 
        muted 
        onCanPlayThrough={videoCanPlayTrough} 
        onTimeUpdate={onPlaying}
        onEnded={restart}
        {...videoProps} 
      />
    </div>
  );
}

export default VideoPlayer;
