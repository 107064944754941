const debug = process.env.NODE_ENV === 'development';

export default {
  debug,
  constraints: {
    audio: false,
    video: {
      facingMode: 'environment',
      width: 800,
    },
  },
  waitCountMax: 40,
  plateTreshold: 0.4,
  noPlateTreshold: 0.8,
  emptyTreshold: 0.3,
  dirtyTreshold: 0.25,
  fullTreshold: 0.4,
  maxScans: 3,
  scanTimeout: 25000,
  scanFps: 1000/2,
  pictureWidth: 800,
  apiUrl: debug ? 'https://luncfee9w130.wtstaging.io/api/savepicture' : 'https://lunchbuddies.lunchgarden.be/api/savepicture',
  suggestionsUrl: 'https://www.lunchgarden.be/LANG/menu?robot=true',
  mqttUrl: '127.0.0.1',
  compositionName: 'LGPlateScanner',
  compStartTimeout: 15000,
  volume: 60,
  minimumDockedWaitTime: debug ? 1 : 20, // in minutes, todo: 30 min for live
  chargedBatteryLevel: debug ? 97 : 100,
}
