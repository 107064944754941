/*
export const PLATE = {
  notfound: "b'None_of_the_above'",
  underside: "b'plate_underside'",
  empty: "b'plate_empty_clean'",
  dirty: "b'plate_empty_dirty'",
  full: "b'plate_not_empty'",
};
*/
export const PLATE = {
  notfound: "None_of_the_above",
  underside: "plate_underside",
  empty: "plate_empty_clean",
  dirty: "plate_empty_dirty",
  full: "plate_not_empty",
};