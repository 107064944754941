export const MQTT = {
  connect: 'connect',
  message: 'message',
  button: 'zbos/sensors',
  pause: 'zbos/composition/pause',
  resume: 'zbos/composition/resume',
  start: 'zbos/composition/start',
  stop: 'zbos/composition/stop',
  list: 'zbos/composition/list',
  cerror: 'zbos/composition/error',
  error: 'error',
  emptyBattery: 'zbos/slam/charging/required/started',
  batteryCharged: 'zbos/slam/charging/required/stopped',
  batteryChanged: 'zbos/status/battery',
  gotoCharger: 'zbos/slam/charging/goto',
  led: 'zbos/leds/chestlight/set',
  reset: 'zbos/slam/location/reset/chargingstation',
  status: '',
  volume: 'zbos/audio/volume',
};