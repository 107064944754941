import React, { useState, useContext } from 'react';
import { BotContext } from '../../common/context/bot.context';
import './form.scss';
import config from '../../common/config';

function Form() {
  const { 
    botid, 
    botname, 
    lang, 
    updateBotId, 
    updateBotName, 
    changeLanguage,
    toggleForm,
    botlocation,
    updateBotLocation,
  } = useContext(BotContext);

  const [ localBotId, updateLocalBotId ] = useState(botid);
  const [ localBotName, updateLocalBotName ] = useState(botname);
  const [ localBotLang, updateLocalBotLang ] = useState(lang);
  const [ localBotLocation, updateLocalBotLocation ] = useState(botlocation);

  const onLocalBotIdChange = (e) => {
    updateLocalBotId(e.currentTarget.value);
  };
  const onLocalBotNameChange = (e) => {
    updateLocalBotName(e.currentTarget.value);
  };
  const onLocalBotLocationChange = (e) => {
    updateLocalBotLocation(e.currentTarget.value);
  };
  const onLocalBotLangChange = (e) => {
    updateLocalBotLang(e.currentTarget.value);
  };

  const onSave = (e) => {
    e.preventDefault();

    updateBotId(localBotId);
    updateBotName(localBotName);
    changeLanguage(localBotLang);
    updateBotLocation(localBotLocation);

    if (localBotId && localBotName && localBotLang && localBotLocation) {
      toggleForm(false);
    }
  };

  return (
    <div className="page form__wrapper">
      <div className="form">
        <div className="form__content">
          <form onSubmit={onSave}>
            <div className="formitem">
              <label className="formitem__label" htmlFor="botid">Bot id</label>
              <select className="formitem__input" id="botid" value={ localBotId } onChange={onLocalBotIdChange}>
                <option>-</option>
                <option value="debug">Robot debug (do not select)</option>
                <option value="robot1">Robot 1</option>
                <option value="robot2">Robot 2</option>
                <option value="robot3">Robot 3</option>
              </select>
            </div>
            
            <div className="formitem">
              <label className="formitem__label" htmlFor="botname">Bot name</label>
              <select className="formitem__input" id="botname" value={ localBotName } onChange={onLocalBotNameChange}>
                <option>-</option>
                <option value="debug">Name Debug (do not select)</option>
                <option value="bob">Bob</option>
                <option value="elly">Elly</option>
                <option value="pol">Pol</option>
              </select>
            </div>

            <div className="formitem">
              <label className="formitem__label" htmlFor="location">Bot location</label>
              <select className="formitem__input" id="location" value={ localBotLocation } onChange={onLocalBotLocationChange}>
                <option>-</option>
                <option value="Debug">Location Debug (do not select)</option>
                <option value="Wilrijk">Wilrijk</option>
                <option value="Bonheiden">Bonheiden</option>
                <option value="Bierges">Bierges</option>
                <option value="Demo">Demo</option>
              </select>
            </div>

            <div className="formitem">
              <label className="formitem__label">Default language</label>
              <div className="formitem__group">
              <div className="formitem__group__item">
                <input type="radio" id="botlang_nl" name="botlang" value="nl" onChange={onLocalBotLangChange} checked={localBotLang === 'nl'} />
                <label htmlFor="botlang_nl">NL</label>
              </div>
              <div className="formitem__group__item">
                <input type="radio" id="botlang_fr" name="botlang" value="fr" onChange={onLocalBotLangChange} checked={localBotLang === 'fr'} />
                <label htmlFor="botlang_fr">FR</label>
              </div>
              </div>
            </div>

            <div className="form__actions">
              <button className="form__action" type="submit" disabled={!(localBotId && localBotName && localBotLang && localBotLocation)}>Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Form;