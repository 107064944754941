import React, { useContext } from 'react';
import { BotContext } from '../../common/context/bot.context';
import './langswitch.scss';

function LangSwitch() {
  const { updateLang, showLoader, pauseComp } = useContext(BotContext);

  const onButtonClick = (newLang) => {
    showLoader();
    updateLang(newLang);
    pauseComp();
  };

  return (
    <div className="langswitch">
      <div className="langswitch__content">
        <div className="langswitch__button langswitch__button--nl" onClick={() => onButtonClick('nl')} />
        <div className="langswitch__button langswitch__button--fr" onClick={() => onButtonClick('fr')} />
      </div>
    </div>
  );
}

export default LangSwitch;