import config from "../config";
import axios from 'axios';

export const save = async (data = {}) => await axios.post(config.apiUrl, data);

export const getSuggestions = async (lang) => {
  const response = await axios.get(config.suggestionsUrl.replace('LANG', lang));

  return response.data;
};
