import React, { useContext } from 'react';
import './ready.scss';
import { PHASE } from '../../common/constants/story.constants';
import { BotContext } from '../../common/context/bot.context';

function ReadyButton() {
  const { updatePhase } = useContext(BotContext);

  return (<div className="readybutton" onClick={() => updatePhase(PHASE.end) } />);
}

export default ReadyButton;
