import React, { useState, useEffect, useContext } from 'react';
import { markers } from './common/markers.json';
import VideoPlayer from './ui/videoplayer/VideoPlayer';
import Log from './ui/log/Log';
import Loading from './ui/loading/Loading';
import Camera from './ui/camera/camera';
import { BotContext } from './common/context/bot.context';
import config from './common/config';
import Form from './ui/form/Form';
import { PHASE } from './common/constants/story.constants';
import SuggestionsTrigger from './ui/suggestions/SuggestionsTrigger';
import Suggestions from './ui/suggestions/Suggestions';
import './App.scss';
import LangSwitch from './ui/langswitch/Langswitch.js';
import ReadyButton from './ui/ready/ReadyButton.js';
import Fullscreen from './ui/fullscreen/Fullscreen.js';
import Battery from './ui/battery/Battery.js';

function App() {
  const [ scannerShown, toggleScanner ] = useState(false);
  const [ video, updateVideo ] = useState(null);

  const { 
    formShown, 
    log, 
    addToLog, 
    clearLog, 
    loading, 
    botname, 
    phase, 
    updatePhase, 
    showSuggestions,
    suggestions,
    lang,
    fullscreen,
    charging,
    batteryLevel,
    connected
  } = useContext(BotContext);

  const onLogClear = (e) => {
    e.stopPropagation();
    clearLog();
  }

  const accessScanner = () => {
    if (phase === PHASE.start || phase === PHASE.waiting) {
      updatePhase(PHASE.zoomin);
    }
  }

  const loadVideo = async () => {
    const { default: src } = await import(`./media/${botname}/video_${ lang }.mp4`);

    updateVideo(src);
  };

  useEffect(() => {
    if (phase === PHASE.scanning && !scannerShown) {
      toggleScanner(true);
    } else if (scannerShown) {
      toggleScanner(false);
    }
  }, [ phase ]);

  useEffect(() => {
    if (botname && lang) {
      loadVideo();
    }
  }, [ botname, lang ]);

  return (
    <div className={ `page container bot--${botname} lang--${lang}${ config.debug ? ' container--debug' : '' }` }>
      <div className="scanner" onClick={accessScanner} />

      { connected && (config.debug || charging || batteryLevel < 30) ? (<Battery />) : null }

      { !connected || (/*!config.debug &&*/ !fullscreen) ? <Fullscreen /> : null }

      { phase === PHASE.start || phase === PHASE.waiting ? (
        <>
          { suggestions.length ? <SuggestionsTrigger /> : null }
          <LangSwitch />
        </>
      ) : null }

      { phase === PHASE.empty ? (<ReadyButton />) : null }

      { video ? (<VideoPlayer src={video} markers={markers} />) : null }
      { scannerShown ? (<Camera addToLog={addToLog} />) : null }

      { showSuggestions ? (<Suggestions />) : null }
      { loading ? (<Loading />) : null }
      { formShown ? (<Form />) : null }
      
      { config.debug ? (<Log records={ log } onClear={onLogClear} />) : null }
    </div>
  );
}

export default App;
