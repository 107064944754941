export const PHASE = {
  start: 'start',
  waiting: 'waiting',
  zoomin: 'zoomin',
  scanning: 'scanning',
  paused: 'paused',
  zoomout: 'zoomout',
  empty: 'empty',
  dirty: 'dirty',
  noplate: 'noplate',
  qr: 'qr',
  end: 'end'
};

export const SCENE = {
  start: 'start',
  waiting1: 'waiting_1',
  waiting2: 'waiting_2',
  waiting3: 'waiting_3',
  zoomin: 'zoom_in',
  scanning: 'scanning',
  empty: 'plate_empty',
  full: 'plate_not_empty',
  noplate: 'no_plate',
  end: 'end',
};