import React, { useEffect, useState, useContext, useRef } from 'react';
import CONFIG from '../../common/config';
import { BotContext } from '../../common/context/bot.context';
import config from '../../common/config';
import { PHASE } from '../../common/constants/story.constants';
import { save } from '../../common/services/backend.service';
import 'tracking/build/tracking';
import 'tracking/build/data/face-min.js';
import 'tracking/build/data/eye-min.js';
import 'tracking/build/data/mouth-min.js';
import './camera.scss';
import { PLATE } from '../../common/constants/ai.constants';

function Camera() {
  const previewRef = useRef();
  const canvasRef = useRef();
  const blurRef = useRef();

  const { 
    addToLog, 
    updatePhase, 
    model, 
    botid, 
    lang, 
    stream, 
    bs,
  } = useContext(BotContext);
  
  const [ videoLoaded, updateVideoLoaded ] = useState(false);
  const [ empties, updateEmpties ] = useState(0);
  const [ fulls, updateFulls ] = useState(0);
  const [ noPlates, updateNoPlates ] = useState(0);
  const [ label, updateLabel ] = useState('{}');

  let scanTimer = null;
  let scanTimeout = null;

  // const tracker = new window.tracking.ObjectTracker(['face', 'eye', 'mouth']);
  const tracker = new window.tracking.ObjectTracker('face');
  tracker.setInitialScale(4);
  tracker.setStepSize(1.1);
  tracker.setEdgesDensity(0.05);

  const drawImage = () => {
    const { current: $preview } = previewRef;
    const { current: $canvas } = canvasRef;
    // const { current: $blurvas } = blurRef;

    if ($canvas) {
      const ctx = $canvas.getContext('2d');
      const ar = $preview.offsetWidth / $preview.offsetHeight;
      $canvas.width = config.pictureWidth;
      $canvas.height = config.pictureWidth / ar;
      ctx.drawImage($preview, 0, 0, $canvas.width, $canvas.height);
      
      // const tracker = new window.tracking.ObjectTracker(['face', 'eye', 'mouth']);
      // tracker.setInitialScale(4);
      // tracker.setStepSize(1.1);
      // tracker.setEdgesDensity(0.05);

      // const bctx = $blurvas.getContext('2d');
      
      // tracker.once('track', (event) => {
      //   if (event.data.length) {
      //     event.data.forEach((face) => {
      //       // const [ face ] = event.data;

      //       $blurvas.width = face.width;
      //       $blurvas.height = face.height;
  
      //       bctx.filter = 'blur(10px)';
      //       bctx.drawImage($canvas, face.x, face.y, face.width, face.height, 0, 0, face.width, face.height);
  
      //       ctx.drawImage($blurvas, face.x, face.y, face.width, face.height);
      //     });
      //   }
      // });

      // window.tracking.track('#canvas', tracker);
    }
  };

  const onScan = async (skip) => {
    const { current: $preview } = previewRef;

    clearTimeout(scanTimer);
    
    if (!$preview || !model) { 
      scanTimer = setTimeout(() => onScan(), config.scanFps); 
      return; 
    }

    const predictions = await model.classify($preview);

    if (skip) {
      scanTimer = setTimeout(() => onScan(), config.scanFps); 
      return; 
    }

    const winnerPredictions = [...predictions].sort(({ prob: probA }, { prob: probB }) => {
      if (probA > probB) { return -1; }
      if (probA < probB) { return 1; }
      return 0;
    });

    console.log('winner predictions', winnerPredictions);

    if (winnerPredictions[0].prob >= CONFIG.plateTreshold) {
      const { label: dirtyLabel } = winnerPredictions[0];
      const label = dirtyLabel.replace(/(b?')/g, '');

      if (label === PLATE.empty || label === PLATE.dirty) {
        addToLog('PLATE EMPTY');
        updateEmpties((prevEmpties) => prevEmpties + 1);

        if (config.debug) { console.log('empty', winnerPredictions); }
      } else if (label === PLATE.full) {
        addToLog('PLATE not empty');
        updateFulls((prevFulls) => prevFulls + 1);
        if (config.debug) { console.log('full', winnerPredictions); }
      } else {
        updateNoPlates((prevPlates) => prevPlates + 1);
        addToLog('PLATE not found');
      }
    } else {
      updateNoPlates((prevPlates) => prevPlates + 1);
      addToLog('PLATE not found');
    }

    try {
      drawImage();
    } catch(e) {
      bs.notify(e);
    }
    updateLabel(JSON.stringify(winnerPredictions || {}));

    scanTimer = setTimeout(() => onScan(), config.scanFps); 
  };

  const blurFaces = (faces = []) => {
    const { current: $canvas } = canvasRef;
    const { current: $blurvas } = blurRef;

    const ctx = $canvas.getContext('2d');
    const bctx = $blurvas.getContext('2d');

    console.log('faces found', faces);

    faces.forEach((face) => {
      $blurvas.width = face.width;
      $blurvas.height = face.height;

      bctx.filter = 'blur(10px)';
      bctx.drawImage($canvas, face.x, face.y, face.width, face.height, 0, 0, face.width, face.height);

      ctx.drawImage($blurvas, face.x, face.y, face.width, face.height);
    });
  };
  
  const saveResults = (answer = '', phase) => {
    const { current: $canvas } = canvasRef;

    if (!$canvas) { return; }

    tracker.once('track', (event) => {
      console.log('tracked', event.data);

      if (event.data.length) {
        blurFaces(event.data);
      }
      
      const picture = $canvas.toDataURL('image/jpeg', .7);

      save({
        robot: botid,
        answer,
        label,
        lang,
        picture,
      });

      updatePhase(phase);
    });

    window.tracking.track('#canvas', tracker);
  };

  useEffect(() => {
    if (empties > config.maxScans) {
      saveResults('empty', PHASE.empty);
    }

    if (fulls > config.maxScans) {
      saveResults('full', PHASE.dirty);
    } 
  }, [empties, fulls]);

  useEffect(() => {
    if (model && videoLoaded) {
      onScan();
    }
  }, [model, videoLoaded]);

  useEffect(() => {
    if (stream) {
      const { current: $preview } = previewRef;

      if (config.debug) { console.log('PREVIEW LOAD'); }
      const localMediaStream = stream;
      $preview.onloadedmetadata = () => { 
        $preview.play(); 
        if (config.debug) { console.log('PREVIEW LOADED'); }
      }
      $preview.srcObject = localMediaStream;
    }
  }, [stream]);

  useEffect(() => {
    scanTimeout = setTimeout(() => {
      saveResults('no_plate');
      updatePhase(PHASE.noplate)
    }, config.scanTimeout);
    
    return () => {
      const { current: $preview } = previewRef;

      clearTimeout(scanTimer);
      clearTimeout(scanTimeout);

      scanTimer = null;
      scanTimeout = null;

      if ($preview) {
        $preview.srcObject = null;
      }
    }
  }, []);

  return (
    <div className="page camera">
      <div className={`page camera__content${ videoLoaded ? ' loaded' : '' }`}>
        <canvas ref={blurRef} id="blurvas" />
        <canvas ref={canvasRef} id="canvas" />
        <video ref={ previewRef } id="preview" className={`page`} playsInline onLoadedData={ () => updateVideoLoaded(true) } />
        <div className="page scan__overlay">
          <div className="scantimer">
            <div className="scantimer__indicator indicator--empty" style={{ width: `${(empties * 100 / config.maxScans)}%` }}></div>
            <div className="scantimer__indicator indicator--full" style={{ width: `${(fulls * 100 / config.maxScans)}%` }}></div>
            <div className="scantimer__indicator indicator--noplate" style={{ width: `${(noPlates * 100 / (config.scanTimeout / config.scanFps))}%` }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Camera;
