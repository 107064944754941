import React, { useContext } from 'react';
import { BotContext } from '../../common/context/bot.context';
import './fullscreen.scss';
import Volume from '../volume/Volume';

function Fullscreen() {
  const { goFullscreen, loading, connected, model, disableBack } = useContext(BotContext);

  const onStartClicked = () => {
    disableBack();
    goFullscreen();
  };

  return (
    <div className="page fullscreen">
      <div className="fullscreen__content">
        { connected && false ? (<Volume />) : null }
        <button className="fullscreen__button action" disabled={loading || !connected || !model} onClick={onStartClicked}>Start</button>
        <div className="fullscreen__messages">
          { loading ? (<p>loading...</p>) : null }
          { !connected ? (<p>connecting...</p>) : null }
        </div>
      </div>
    </div>
  );
}

export default Fullscreen;